@use '../util' as *; // looking for variable names in the util folder?
// https://codepen.io/SaraSoueidan/pen/jpBbrq

.toggle { // toggle
    display: grid; // looks the same as mobile and desktop
    grid-template-columns: 1fr rem(48);
    gap: rem(4.8) rem(8); // top bottom gap
    border: none;
    margin: 0;
    
    label { // & inherits label
        font-size: rem(14);
        color: var(--toggle);
        // grid-column: 1 / 2;
        
        &[for='dark']{
            grid-column: 1 / 2;
            line-height: rem(24);
            margin-right: rem(13);
        }
        // &[for='system']{
        //     grid-column: 1 / 4;
        //     grid-row: 2 / 3;
        //     justify-self: center;
        //     align-self: start;
        // }
        // &[for='light']{
        //     grid-column: 3 / 4;
        // }
    }

    &__wrapper { // toggle__wrapper
        position: relative;
        // grid-column: 2 / 3;
        height: rem(24);
    }

    input[type="radio"]{
        opacity: 0;
        margin: 0 rem(-2) 0 rem(-2);
        width: rem(24);
        height: rem(24);

        &:focus ~ .toggle__button {
            border: 2px solid white;
        }
    }

    &__background {
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        border-radius: rem(12);
        overflow: hidden;
        background: var(--toggle-bg);
        pointer-events: none;
    }


    &__button {
        position: absolute;
        left: rem(3);
        right: 100%;
        top: rem(3);
        width: rem(18);
        height: rem(18);
        border-radius: 50%;
        background-color: var(--toggle-button);
        transition: all 150ms ease-in-out;
    }

    #light:checked ~ .toggle__button { // if light is selected in the browser changes button
        left: calc(100% - 21px);
        right: 3px;
    }

    #system:checked ~ .toggle__button {
        left: 50%;
        right: auto;
        transform: translate(-50%);
    }
}