@use '../util' as *;

.header {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(36);
    color: var(--text-color1);
    font-weight: 700;

    @include breakpoint(medium) {
        justify-content: space-between;
        align-items: center;
    }
    &__title {
        width: 100%;

        @include breakpoint(medium) {
            width: auto;
        }
    }

    &__subtitle { // called a selector
        font-size: rem(14);
    }

    &__container {

    }
}