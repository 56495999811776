@use '../util' as *;
html {
    font-size: 100%;
    box-sizing: border-box; // padding to prevent text from being larger than what you want to set it to
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-inter);
    background: var(--background);
    color: var(--dark-text2);
}

.visually-hidden {
    position: absolute;
    left: rem(-10000);
    top: auto;
    width: rem(1);
    height: rem(1);
    overflow: hidden;
}