// 640px, 1150px, 1400px
$breakpoints-up: (
    'medium': '40em',
    'large': '71.875em',
    'xlarge': '87.5em',
);

// 639px, 1149px, 1399px
$breakpoints-down: (
    'small': '39.9375em',
    'medium': '71.8125em',
    'large': '87.4375em',
);

@mixin breakpoint($size) {
    @media (min-width: map-get($breakpoints-up, $size)) {
        @content;
    }
}

@mixin breakpoint-down($size) {
    @media (max-width: map-get($breakpoints-down, $size)) {
        @content;
    }
}

// h1 {
//     @include breakpoint(large){ <-- only have to change the value of large at the top of the file
//         font-size: 3rem;            rather than changing for each time this is called
//     }
// }
//
// versus
//
// @media (min-width: 87.5em) {
//     h1{
//         font-size: 3rem;
//     }
// }